import React from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import Register from "./Register";
import Login from "./Login";
import devPage from "./devPage";
import userPage from "./userPage";

class App extends React.Component {
    render(){
        return(
            <Router >
                <div>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/Register" component={Register} />
                    <Route exact path="/DevPage/:data" component={devPage} />
                    <Route exact path="/UserPage/:data" component={userPage} />
                </div>
            </Router>
        )
    }
}
export default App;