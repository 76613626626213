import React, { Component } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Input,
    Label
} from "reactstrap";

export default class BuyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: this.props.activeItem
        };
    }
    handleChange = e => {
        let { name, value } = e.target;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        const activeItem = { ...this.state.activeItem, [name]: value };
        this.setState({ activeItem });
    };
    handleStockID = e => {
        let { name, value } = e.target;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        if(isNaN(value)){
            value=value.replace(/[^\d]/g,'');
            console.log("Invalid Input");
        }
        const activeItem = { ...this.state.activeItem, [name]: value };
        this.setState({ activeItem });
    };

    render() {
        const { toggle, onSave } = this.props;
        const flag = this.props.flag;
        const date = this.props.date;
        const flag_bool = flag === 1 || flag === 2;
        return (
            <Modal isOpen={true} toggle={toggle}>
                <ModalHeader toggle={toggle}> Stock </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="StockName" hidden={flag === 3 || flag === 4 || flag === 5}>StockName</Label>
                            <Input
                                type="text"
                                name="StockName"
                                value={this.state.activeItem.StockName}
                                onChange={this.handleStockID}
                                placeholder="Enter Stock Name"
                                readOnly={flag_bool}
                                hidden={flag === 3 || flag === 4 || flag === 5}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="Date" hidden={flag ===4 || flag ===5}>Date</Label>
                            <Input
                                type="text"
                                name="Date"
                                value={this.state.activeItem.Date}
                                onChange={this.handleChange}
                                placeholder="Enter Date"
                                readOnly={flag_bool && flag !== 3}
                                hidden={flag ===4 || flag ===5}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="StartDate" hidden={flag !==4 && flag !==5}>StartDate</Label>
                            <Input
                                type="text"
                                name="StartDate"
                                value={this.state.activeItem.StartDate}
                                onChange={this.handleChange}
                                placeholder="Enter StartDate"
                                hidden={flag !==4 && flag !== 5}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="EndDate" hidden={flag !==4 && flag !== 5}>EndDate</Label>
                            <Input
                                type="text"
                                name="EndDate"
                                value={this.state.activeItem.EndDate}
                                onChange={this.handleChange}
                                placeholder="Enter EndDate"
                                hidden={flag !==4 && flag !== 5}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="Shares" hidden={flag === 3 || flag === 4 || flag ===5}>Shares</Label>
                            <Input
                                type="text"
                                name="Shares"
                                value={this.state.activeItem.Shares}
                                onChange={this.handleChange}
                                placeholder="Enter Shares"
                                hidden={flag === 3 || flag ===4 || flag ===5}
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => onSave(this.state.activeItem)}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}