import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import axios from 'axios';
// import Login from "./Login";
// import ReactDOM from "react-dom";
import {Link} from "react-router-dom";

class Register extends Component {
    constructor(props){
        super(props);
        this.state={
            username:'',
            password:'',
            driverlicense:'',
            datebirth:'',
            ssn:''
        }
    }
    handleClick(event){
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        var apiBaseUrl = "https://backend.cs411team1.web.illinois.edu/user/";
        console.log("values",this.state.username,this.state.password);
        //To be done:check for empty values before hitting submit
        var self = this;
        var payload={
            "email":this.state.username,
            "password":this.state.password
        }
        axios.get(proxyurl+apiBaseUrl+`${this.state.driverlicense}/${this.state.username}/${this.state.password}/${this.state.datebirth}/${this.state.ssn}`)
            .then(function (response) {
                var data = response.data.split(" ");
                if(data[0] === "Register"){
                    alert("Register Failed");
                }else{
                    alert("Register Success");
                }
                console.log(response);
            });
    }
    render() {
        return (
            <div style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <MuiThemeProvider>
                    <div>
                        <AppBar
                            title="Register"
                        />
                        <br/>
                        <TextField
                            hintText="Enter your Username"
                            type="Username"
                            floatingLabelText="Username"
                            onChange = {(event,newValue) => this.setState({username:newValue})}
                        />
                        <br/>
                        <TextField
                            type = "password"
                            hintText="Enter your Password"
                            floatingLabelText="Password"
                            onChange = {(event,newValue) => this.setState({password:newValue})}
                        />
                        <br/>
                        <TextField
                            type = "driverlicense"
                            hintText="Enter your driverlicense"
                            floatingLabelText="Driverlicense"
                            onChange = {(event,newValue) => this.setState({driverlicense:newValue})}
                        />
                        <br/>
                        <TextField
                            type = "datebirth"
                            hintText="Enter your datebirth"
                            floatingLabelText="Datebirth"
                            onChange = {(event,newValue) => this.setState({datebirth:newValue})}
                        />
                        <br/>
                        <TextField
                            type = "ssn"
                            hintText="Enter your ssn"
                            floatingLabelText="Ssn"
                            onChange = {(event,newValue) => this.setState({ssn:newValue})}
                        />
                        <br/>
                        <Link to="/" style={{color:'black'} }>
                            <RaisedButton label="Submit" primary={true} style={style} onClick={(event) => this.handleClick(event)}/>
                        </Link>
                    </div>
                </MuiThemeProvider>
            </div>
        );
    }
}
const style = {
    margin: 15,
};
export default Register;