import React, { Component } from "react";
import BuyModal from "./components/BuyModal";
import SearchStockModal from "./components/SearchStockModal";
import axios from "axios";
import Table from 'react-bootstrap/Table';
import LineA from "./LineA";

const proxyurl = "https://cors-anywhere.herokuapp.com/";
const backendurl = "https://backend.cs411team1.web.illinois.edu/user/";
const backendtradeurl = "https://backend.cs411team1.web.illinois.edu/trade/";
const options = {
    headers: {"Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
        "Access-Control-Allow-Headers": "X-Requested-With, content-type, Authorization"}
};

function UserBalanceandDate(props){
    return <h5>Date: {props.date} &emsp; My Current Balance: {props.mybalance} &emsp; My Total Balance: {props.total}</h5>;
}

class UserPage extends Component {
    constructor(props) {
        super(props);
        var data = JSON.parse(props.match.params.data);
        var {userid,username} = data;
        console.log(userid);
        this.state = {
            //viewCompleted: false,
            flag: 0, // 1 buy 2 sell 3 change 4 plot 5 Highest Price 10 search
            userid: userid,
            username: username,
            Date: "",
            MyBalance: "",
            Total:"",
            userItem:{
                avgCost:"",
                Shares:"",
                StockName:"",
            },
            userList:[],
            activeItem: {
                StockName: "",
                LowPrice: "",
                HighPrice: "",
                OpenPrice: "",
                ClosePrice: "",
                Volume: "",
                PriceNow:"",
                Shares:"0",
                Date:"",
                StartDate:"",
                EndDate:""
            },
            stockList: [],
            stockNameWatchList:[],
            plotStockName: "",
            plotList:[],
            slist:[],
            stocktodaylist:[],
        };
        this.refreshList();
    }

    searchUpdate(data){
        if(data.startsWith("No")){
            alert("No record");
            console.log(data);
            return;
        }
        if(data.length > 0){
            let value = data.split(",");
            const item = {
                StockID: "",
                StockName:"",
                LowPrice: "",
                HighPrice: "",
                OpenPrice: "",
                ClosePrice: "",
                Volume: "",
                Shares: "0",
                Date: this.state.Date,
                StartDate:"",
                EndDate:""
            };
            item.StockID = value[0];
            item.StockName = value[7];
            item.LowPrice = value[2];
            item.HighPrice = value[3];
            item.OpenPrice = value[4];
            item.ClosePrice = value[5];
            item.Volume = value[6];
            this.state.stockList = this.state.stockList.filter((newitem) => (newitem.StockID !== item.StockID));
            this.state.stockNameWatchList = this.state.stockNameWatchList.filter((newitem) => (newitem !== item.StockName));
            this.state.stockList.push(item);
            this.state.stockNameWatchList.push(item.StockName);
            console.log(this.state.stockNameWatchList);
            this.setState({stockList: this.state.stockList});
        }
    }

    changeDateUpdate(data){
        let value = data.split(" ");
        const justice = {
            first: "",
            second: "",
            third:""
        };
        justice.first = value[0];
        justice.second = value[1];
        justice.third = value[2];
        if (justice.third === "Success!"){
            this.refreshList();
            var i = 0;
            var stockNameWatchList = [...this.state.stockNameWatchList];
            for(i  = 0; i < stockNameWatchList.length; i++){
                axios
                    .get(proxyurl+backendtradeurl+`${stockNameWatchList[i]}/${this.state.Date}`)
                    .then(res => this.searchUpdate(res.data));
            }
        }else{
            alert("Change Date failed!");
        }
    }

    buyUpdate(data, item){
        let value = data.split(" ");
        const justice = {
            first: "",
            second: "",
            third:""
        };
        justice.first = value[0];
        justice.second = value[1];
        justice.third = value[2];
        if (justice.third === "Success!"){
            this.refreshList();
        }else{
            alert("Buy failed!");
        }
    }

    SellUpdate(data, item){
        let value = data.split(" ");
        const justice = {
            first: "",
            second: "",
            third:""
        };
        justice.first = value[0];
        justice.second = value[1];
        justice.third = value[2];
        if (justice.third === "Success!"){
            this.refreshList();
        }else{
            alert("Sell failed!");
        }
    }

    PlotUpdate(data){
        //console.log(data);
        data = JSON.parse(data);
        var data_array = data["plotdate"];
        if(data_array.length === 0){
            return;
        }
        const plotdatalist = [];
        this.setState({plotList: plotdatalist});
        var i = 0;
        var item;
        for (i = 0; i < data_array.length; i++){
            item = {
                label: "",
                x: 0,
                y: 0
            };
            item.label = data_array[i]["date"];
            item.x = i;
            item.y = data_array[i]["price"];
            plotdatalist.push(item);
        }
        this.setState({plotList: plotdatalist});
    }

    StockTodayUpdate(data){
        data = JSON.parse(data);
        var data_array = data["stocknamelist"];
        //console.log(data_array);
        if(data_array.length === 0){
            alert("No stocks today !!!");
            return;
        }
        const empty_array = [];
        this.setState({stocktodaylist: empty_array});
        this.setState({stocktodaylist: data_array});
        //console.log(this.state.stocktodaylist);
    }

    HighestPriceUpdate(data){
        //console.log(data);
        data = JSON.parse(data);
        var data_array = data["slist"];
        if(data_array.length === 0){
            return;
        }
        const temp_slist = [];
        this.setState({slist: temp_slist});
        var i = 0;
        var item;
        for (i = 0; i < data_array.length; i++){
            item = {
                avgPrice: "",
                StockName: ""
            };
            item.avgPrice = data_array[i]["avgPrice"];
            item.StockName = data_array[i]["StockName"];
            temp_slist.push(item);
        }
        this.setState({slist: temp_slist});
    }

    deleteUpdate(data, item){
        let value = data.split(" ");
        const justice = {
            first: "",
            second: "",
            third:""
        };
        justice.first = value[0];
        justice.second = value[1];
        justice.third = value[2];
        if (justice.third === "Success!"){
            this.setState({ stockList: this.state.stockList.filter((newitem) => newitem.StockID !== item.StockID || newitem.Date !== item.Date) });
        }
    }

    refreshUserList(data){
        data = JSON.parse(data);
        var {balance,date,stock,Total} = data;
        this.setState({Total: Total});
        this.setState({Date: date});
        this.setState({MyBalance: balance});
        this.setState({userList: stock});
    }

    refreshList = () => {
        axios
            .get(proxyurl+backendurl+'/' +`${this.state.userid}`)
            .then(res => this.refreshUserList(res.data))
            .catch(err => console.log(err));
    };

    renderHighestPriceItems = () => {
        const newItems = this.state.slist;
        return newItems.map(item => (
            <li
                key={item.StockName}
                className="list-group-item d-flex justify-content-between align-items-center"
            >
            <span
                className={`todo-title mr-2`}
                title={item.StockName}
            >
                <Table striped bordered hover>
                    <thead>
                <tr>
                    <th>Highest Price Stock</th>
                    <th>StockName</th>
                    <th>avgPrice</th>
                </tr>
                </thead>
              <tbody>
                <tr>
                  <td>#</td>
                  <td>{item.StockName}</td>
                    <td>{Math.round(item.avgPrice*100)/100}</td>
                </tr>
              </tbody>
            </Table>

            </span>
            </li>
        ));
    };

    renderStockTodayItems = () => {
        const newItems = this.state.stocktodaylist;
        return <li className="list-group-item d-flex justify-content-between align-items-center"><span
            className={`todo-title mr-2`}
            title={this.state.Date}
        ><b>Stock Today:</b> {newItems.toString()} ...</span></li>
    };

    renderItems = () => {
        const newItems = this.state.userList;
        return newItems.map(item => (
            <li
                key={item.StockName + this.state.Date}
                className="list-group-item d-flex justify-content-between align-items-center"
            >
            <span
                className={`todo-title mr-2`}
                title={this.state.Date}
            >
                <Table striped bordered hover>
                    <thead>
                <tr>
                    <th>User Stock</th>
                    <th>StockName</th>
                    <th>Shares</th>
                    <th>avgCost</th>
                </tr>
                </thead>
              <tbody>
                <tr>
                  <td>#</td>
                  <td>{item.StockName}</td>
                  <td>{item.Shares}</td>
                    <td>{Math.round(item.avgCost*100)/100}</td>
                </tr>
              </tbody>
            </Table>

            </span>
            </li>
        ));
    };
    renderSearchItems = () => {
        const newItems = this.state.stockList;
        return newItems.map(item => (
            <li
                key={item.StockName + this.state.Date}
                className="list-group-item d-flex justify-content-between align-items-center"
            >
            <span
                className={`todo-title mr-2`}
                title={this.state.Date}
            >
                <Table striped bordered hover>
                    <thead>
                <tr>
                    <th>Watch List</th>
                    <th>StockName</th>
                    <th>LowPrice</th>
                    <th>HighPrice</th>
                    <th>OpenPrice</th>
                    <th>ClosePrice</th>
                    <th>Volume</th>
                </tr>
                </thead>
              <tbody>
                <tr>
                  <td>#</td>
                  <td>{item.StockName}</td>
                  <td>{Math.round(item.LowPrice*100)/100}</td>
                    <td>{Math.round(item.HighPrice*100)/100}</td>
                    <td>{Math.round(item.OpenPrice*100)/100}</td>
                    <td>{Math.round(item.ClosePrice*100)/100}</td>
                    <td>{item.Volume}</td>
                </tr>
              </tbody>
            </Table>

            </span>
                <span>
                    <button
                        onClick={() => this.PlotItem(item)}
                        className="btn btn-info mr-2"
                    >
                        Plot{" "}
                    </button>
              <button
                  onClick={() => this.BuyItem(item)}
                  className="btn btn-info mr-2"
              >
                  Buy{" "}
              </button>
              <button
                  onClick={() => this.SellItem(item)}
                  className="btn btn-info"
              >
                Sell{" "}
              </button>
            </span>
            </li>
        ));
    };
    toggle = () => {
        this.setState({ modal: !this.state.modal});
    };
    handleSubmit = item => {
        const flag = this.state.flag;

        this.toggle();
        console.log(item);

        if (flag === 1) {
            // buy
            axios
                .get(proxyurl+backendtradeurl+"1/"+`${this.state.userid}/${this.state.Date}/${item.StockName}/${item.Shares}/${item.OpenPrice}`)
                // .post("https://google.com",item)
                .then(res => this.buyUpdate(res.data, item));
            return;
        }
        else if (flag === 2){
            // sell
            axios
                .get(proxyurl+backendtradeurl+"2/"+`${this.state.userid}/${this.state.Date}/${item.StockName}/${item.Shares}/${item.OpenPrice}`)
                // .post("https://google.com",item)
                .then(res => this.SellUpdate(res.data, item));
            return;
        }
        else if (flag === 3){
            var date1 = this.state.Date;
            var date2 = item.Date;

            let list1 = date1.split('-');
            let list2 = date2.split('-');
            var mdate1 = list1[2]+'-'+list1[1]+'-'+list1[0];
            var mdate2 = list2[2]+'-'+list2[1]+'-'+list2[0];

            var rgexp = /(^(((0[1-9]|1[0-9]|2[0-8])[-](0[1-9]|1[012]))|((29|30|31)[-](0[13578]|1[02]))|((29|30)[-](0[4,6,9]|11)))[-](19|[2-9][0-9])\d\d$)|(^29[-]02[-](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;

            var isValidDate2 = rgexp.test(mdate2);

            if(!isValidDate2) {
                alert("Invalid Input Date");
                return;
            }

            if(date1.localeCompare(date2) === 0) {
                alert("Same Date!");
            } else if (date1.localeCompare(date2) === 1){
                alert("Current date is Greater");
            } else{
                console.log("New date is Greater");
                axios
                    .get(proxyurl+backendurl+`${this.state.userid}/${item.Date}/`+'1')
                    .then(res => this.changeDateUpdate(res.data));
                return;
            }
        }
        else if(flag === 4 || flag ===5){
            var date1 = item.StartDate;
            var date2 = item.EndDate;

            let list1 = date1.split('-');
            let list2 = date2.split('-');
            var mdate1 = list1[2]+'-'+list1[1]+'-'+list1[0];
            var mdate2 = list2[2]+'-'+list2[1]+'-'+list2[0];

            var rgexp = /(^(((0[1-9]|1[0-9]|2[0-8])[-](0[1-9]|1[012]))|((29|30|31)[-](0[13578]|1[02]))|((29|30)[-](0[4,6,9]|11)))[-](19|[2-9][0-9])\d\d$)|(^29[-]02[-](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;

            var isValidDate1 = rgexp.test(mdate1);
            var isValidDate2 = rgexp.test(mdate2);

            if(!isValidDate1) {
                alert("Invalid Start Date");
                return;
            }
            if(!isValidDate2) {
                alert("Invalid End Date");
                return;
            }
            let currentdate = this.state.Date;
            if(date1.localeCompare(date2) === 1){
                alert("Start Date is larger than the End Date");
                return;
            }
            if (date2.localeCompare(currentdate) === 1){
                alert("End Date is larger than the current Date");
                return;
            }
            if(flag === 4){
                this.setState({plotStockName: `${item.StockName}`});
                axios
                    .get(proxyurl+backendtradeurl+`${item.StockName}/${item.StartDate}/${item.EndDate}`)
                    .then(res => this.PlotUpdate(res.data));
                return;
            }else{
                axios
                    .get(proxyurl+backendtradeurl+ "2/3/" + `${item.StartDate}/${item.EndDate}`)
                    .then(res => this.HighestPriceUpdate(res.data));
                return;
            }
        }
        else if (flag === 10){
            // search
            axios
                .get(proxyurl+backendtradeurl+`${item.StockName}/${this.state.Date}`)
                .then(res => this.searchUpdate(res.data));
            return;
        }

        // axios
        //     .post("http://cs411team1.web.illinois.edu/api/", item)
        //     .then(res => this.refreshList());
    };
    handleDelete = item => {
        // this.refreshList();
        axios
            .get(proxyurl+`https://backend.cs411team1.web.illinois.edu/polls/4/${item.StockID}/${item.Date}`)
            .then(res => this.deleteUpdate(res.data, item));
    };

    SearchItem = () => {
        const item = { StockName: ""};
        this.setState({flag: 10, activeItem: item, modal: !this.state.modal });
    };
    BuyItem = item => {
        this.setState({flag: 1, activeItem: item, modal: !this.state.modal });
    };
    SellItem = item => {
        this.setState({flag: 2, activeItem: item, modal: !this.state.modal });
    };
    PlotItem = item => {
        this.setState({flag: 4, activeItem: item, modal: !this.state.modal });
    };
    HighestPriceItem = () => {
        const item = { StartDate:"", EndDate:""};
        this.setState({flag: 5, activeItem: item, modal: !this.state.modal });
    };
    ChangeDateItem = () => {
        const item = { Date: this.state.Date};
        this.setState({flag: 3, activeItem: item, modal: !this.state.modal });
    };
    StockTodayItem = () => {
        axios
            .get(proxyurl+backendtradeurl+`${this.state.Date}`)
            .then(res => this.StockTodayUpdate(res.data)).catch(err => alert("No stocks today !!!"));
        return;
    };
    logout = () =>{
        this.props.history.push("/");
    }
    render() {
        console.log(this.state.stockList);
        return (
            <main className="content">
                <h1 className="text-white text-uppercase text-center my-4">{this.state.username}'s Page</h1>
                <div className="row ">
                    <div className="col-md-11 col-sm-11 mx-auto p-0">
                        <div className="card p-3" >
                            <div className="">
                                <button onClick={this.refreshList} className="btn btn-info">
                                    Fetch
                                </button> &nbsp;

                                <button onClick={this.StockTodayItem} className="btn btn-info">
                                    Stock Today
                                </button> &nbsp;

                                <button onClick={this.SearchItem} className="btn btn-info">
                                    Search
                                </button> &nbsp;

                                <button onClick={this.ChangeDateItem} className="btn btn-info">
                                    ChangeDate
                                </button> &nbsp;

                                <button onClick={this.HighestPriceItem} className="btn btn-info">
                                    HighestPrice
                                </button> &nbsp;

                                <button onClick={this.logout} className="btn btn-info">
                                    Logout
                                </button>
                                <UserBalanceandDate date = {this.state.Date} mybalance = {Math.round(this.state.MyBalance*100)/100} total = {Math.round(this.state.Total*100)/100}/>
                            </div>
                            <ul className="list-group list-group-flush">
                                {this.renderStockTodayItems()}
                            </ul>
                            <ul className="list-group list-group-flush">
                                {this.renderHighestPriceItems()}
                            </ul>
                            <ul className="list-group list-group-flush">
                                {this.renderItems()}
                            </ul>
                            <ul className="list-group list-group-flush">
                                {this.renderSearchItems()}
                            </ul>
                        </div>
                    </div>
                </div>
                {this.state.modal ? ( this.state.flag === 10?
                        <SearchStockModal
                            activeItem={this.state.activeItem}
                            toggle={this.toggle}
                            onSave={this.handleSubmit}
                        /> : <BuyModal
                            date = {this.state.Date}
                            flag = {this.state.flag}
                            activeItem={this.state.activeItem}
                            toggle={this.toggle}
                            onSave={this.handleSubmit}
                        />
                ) : null}
                {this.state.flag === 4 ? (this.state.plotList.length !== 0 ? <LineA data = {this.state.plotList} StockName = {this.state.plotStockName}/> : null): null}
            </main>
        );
    }
}
export default UserPage;