import React, { Component } from "react";
import InsertModal from "./components/InsertModal";
import SearchModal from "./components/SearchModal";
import axios from "axios";
import Table from 'react-bootstrap/Table';

const StockItems = [
    {
        StockID: "1",
        Date: "2011-11-17",
        LowPrice: "30.25",
        HighPrice: "32.12",
        OpenPrice: "33.21",
        ClosePrice: "30.22",
        Volume: "1000"
    },
    {
        StockID: "2",
        Date: "2015-12-31",
        LowPrice: "3.25",
        HighPrice: "3.12",
        OpenPrice: "3.21",
        ClosePrice: "3.22",
        Volume: "200"
    },
    {
        StockID: "3",
        Date: "2018-3-15",
        LowPrice: "300.25",
        HighPrice: "400.12",
        OpenPrice: "200.21",
        ClosePrice: "400.12",
        Volume: "2200"
    },
    {
        StockID: "4",
        Date: "2019-9-9",
        LowPrice: "3.25",
        HighPrice: "3.12",
        OpenPrice: "3.21",
        ClosePrice: "3.22",
        Volume: "100"
    }
];
const proxyurl = "https://cors-anywhere.herokuapp.com/";
const backendurl = "https://backend.cs411team1.web.illinois.edu/polls/";
const options = {
    headers: {"Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
        "Access-Control-Allow-Headers": "X-Requested-With, content-type, Authorization"}
};

class DevPage extends Component {
    constructor(props) {
        super(props);
        var data = JSON.parse(props.match.params.data);
        var {userid,username} = data;
        this.state = {
            //viewCompleted: false,
            flag: 1, // 1 insert 2 search 3 edit 4 delete
            userid: userid,
            username: username,
            activeItem: {
                StockID: "",
                Date: "",
                LowPrice: "",
                HighPrice: "",
                OpenPrice: "",
                ClosePrice: "",
                Volume: ""
            },
            stockList: [],
            topList:[],
        };
    }

    searchUpdate(data){
        if(data.length > 0){
            let value = data.split(",");
            const item = { StockID: "",
                Date: "",
                LowPrice: "",
                HighPrice: "",
                OpenPrice: "",
                ClosePrice: "",
                Volume: ""};
            item.StockID = value[0];
            item.Date = value[1];
            item.LowPrice = value[2];
            item.HighPrice = value[3];
            item.OpenPrice = value[4];
            item.ClosePrice = value[5];
            item.Volume = value[6];
            this.state.stockList = this.state.stockList.filter((newitem) => (newitem.StockID !== item.StockID) || (newitem.Date !== item.Date))
            this.state.stockList.push(item);
            this.setState({stockList: this.state.stockList});
        }
    }

    insertUpdate(data, item){
        let value = data.split(" ");
        const justice = {
            first: "",
            second: "",
        };
        justice.first = value[0];
        justice.second = value[1];
        if (justice.second === "Success!"){
            this.state.stockList = this.state.stockList.filter((newitem) => newitem.StockID !== item.StockID || newitem.Date !== item.Date)
            this.state.stockList.push(item);
            this.setState({stockList: this.state.stockList});
        }
    }

    ActiveUserUpdate(data){
        console.log(data);
        data = JSON.parse(data);
        var data_array = data["toplist"];
        if(data_array.length === 0){
            return;
        }
        const topuserlist = [];
        this.setState({topList: topuserlist});
        var i = 0;
        var item;
        for (i = 0; i < data_array.length; i++){
            item = {
                User: "",
                StockWithLargestShare: "",
                NumberOfStocks: "",
                NumberOfTransactions: ""
            };
            item.User = data_array[i]["User"];
            item.StockWithLargestShare = data_array[i]["StockWithLargestShare"];;
            item.NumberOfStocks = data_array[i]["NumberOfStocks"];
            item.NumberOfTransactions = data_array[i]["NumberOfTransactions"];
            topuserlist.push(item);
        }
        this.setState({topList: topuserlist});
    }

    deleteUpdate(data, item){
        let value = data.split(" ");
        const justice = {
            first: "",
            second: "",
        };
        justice.first = value[0];
        justice.second = value[1];
        if (justice.second === "Success!"){
            this.setState({ stockList: this.state.stockList.filter((newitem) => newitem.StockID !== item.StockID || newitem.Date !== item.Date) });
        }
    }

    componentDidMount() {
        this.refreshList();
    }
    refreshList = () => {
        axios
            .get(proxyurl+backendurl)
            .then(res => console.log(res.data))
            // .then(res => this.setState({ stockList: res.data }))
            .catch(err => console.log(err));
    };
    // displayCompleted = status => {
    //     if (status) {
    //         return this.setState({ viewCompleted: true });
    //     }
    //     return this.setState({ viewCompleted: false });
    // };
    renderItems = () => {
        const newItems = this.state.stockList;
        return newItems.map(item => (
            <li
                key={item.StockID + item.Date}
                className="list-group-item d-flex justify-content-between align-items-center"
            >
            <span
                className={`todo-title mr-2`}
                title={item.Date}
            >
                <Table striped bordered hover>
                    <thead>
                <tr>
                    <th>Stock List</th>
                    <th>StockID</th>
                    <th>Date</th>
                    <th>LowPrice</th>
                    <th>HighPrice</th>
                    <th>OpenPrice</th>
                    <th>ClosePrice</th>
                    <th>Volume</th>
                </tr>
                </thead>
              <tbody>
                <tr>
                  <td>#</td>
                  <td>{item.StockID}</td>
                  <td>{item.Date}</td>
                  <td>{item.LowPrice}</td>
                    <td>{item.HighPrice}</td>
                     <td> {item.OpenPrice}</td>
                     <td>{item.ClosePrice}</td>
                     <td>{item.Volume}</td>
                </tr>
              </tbody>
            </Table>

            </span>
                <span>
              <button
                  onClick={() => this.editItem(item)}
                  className="btn btn-info mr-2"
              >
                {" "}
                  Edit{" "}
              </button>
              <button
                  onClick={() => this.handleDelete(item)}
                  className="btn btn-info"
              >
                Delete{" "}
              </button>
            </span>
            </li>
        ));
    };
    renderActiveItems = () => {
        const newItems = this.state.topList;
        return newItems.map(item => (
            <li
                key={item.User}
                className="list-group-item d-flex justify-content-between align-items-center"
            >
            <span
                className={`todo-title mr-2`}
                title={item.User}
            >
                <Table striped bordered hover>
                    <thead>
                <tr>
                    <th>Top List</th>
                    <th>User</th>
                    <th>StockWithLargestShare</th>
                    <th>NumberOfStocks</th>
                    <th>NumberOfTransactions</th>
                </tr>
                </thead>
              <tbody>
                <tr>
                  <td>#</td>
                  <td>{item.User}</td>
                  <td>{item.StockWithLargestShare}</td>
                  <td>{item.NumberOfStocks}</td>
                    <td>{item.NumberOfTransactions}</td>
                </tr>
              </tbody>
            </Table>
            </span>
            </li>
        ));
    };
    toggle = () => {
        this.setState({ modal: !this.state.modal });
    };


    handleSubmit = item => {
        const flag = this.state.flag;

        this.toggle();
        console.log(item);

        if (flag === 1) {
            // insert
            axios
                .get(proxyurl+backendurl+"1/"+`${item.StockID}/${item.Date}/${item.LowPrice}/${item.HighPrice}/${item.OpenPrice}/${item.ClosePrice}/${item.Volume}`)
                // .post("https://google.com",item)
                .then(res => this.insertUpdate(res.data, item));
            return;
        }
        else if (flag === 2){
            // search
            axios
                .get(proxyurl+backendurl+"2/"+`${item.StockID}/${item.Date}`)
                .then(res => this.searchUpdate(res.data));
            return;
        }
        else if (flag === 3){
            // update
            axios
                .get(proxyurl+backendurl+"3/"+`${item.StockID}/${item.Date}/${item.LowPrice}/${item.HighPrice}/${item.OpenPrice}/${item.ClosePrice}/${item.Volume}`)
                .then(res => this.insertUpdate(res.data, item));
            return;
        }
        // axios
        //     .post("http://cs411team1.web.illinois.edu/api/", item)
        //     .then(res => this.refreshList());
    };
    handleDelete = item => {
        // this.refreshList();
        axios
            .get(proxyurl+`https://backend.cs411team1.web.illinois.edu/polls/4/${item.StockID}/${item.Date}`)
            .then(res => this.deleteUpdate(res.data, item));
    };
    InsertItem = () => {
        const item = { StockID: "",
            Date: "",
            LowPrice: "",
            HighPrice: "",
            OpenPrice: "",
            ClosePrice: "",
            Volume: ""};
        console.log(this.state.modal);
        this.setState({flag: 1, activeItem: item, modal: !this.state.modal });
        console.log(this.state.modal);
    };
    SearchItem = () => {
        const item = { StockID: "", Date: ""};
        //发送给server 返回新的item
        this.setState({flag: 2, activeItem: item, modal: !this.state.modal });
    };
    ActiveUserItem = () =>{
        axios
            .get(proxyurl+backendurl+"activateuser")
            .then(res => this.ActiveUserUpdate(res.data));
        return;
    }
    editItem = item => {
        this.setState({flag: 3, activeItem: item, modal: !this.state.modal });
    };
    logout = () =>{
        this.props.history.push("/");
    }
    render() {
        console.log(this.state.stockList);
        return (
            <main className="content">
                <h1 className="text-white text-uppercase text-center my-4">{this.state.username}'s Admin Page</h1>
                <div className="row ">
                    <div className="col-md-11 col-sm-11 mx-auto p-0">
                        <div className="card p-3" >
                            <div className="">
                                <button onClick={this.InsertItem} className="btn btn-info">
                                    Insert
                                </button> &nbsp;

                                <button onClick={this.SearchItem} className="btn btn-info">
                                    Search
                                </button> &nbsp;

                                <button onClick={this.ActiveUserItem} className="btn btn-info">
                                    ActiveUser
                                </button> &nbsp;

                                <button onClick={this.logout} className="btn btn-info">
                                    Logout
                                </button>
                            </div>
                            <ul className="list-group list-group-flush">
                                {this.renderItems()}
                            </ul>
                            <ul className="list-group list-group-flush">
                                {this.renderActiveItems()}
                            </ul>
                        </div>
                    </div>
                </div>
                {this.state.modal ? ( this.state.flag === 2?
                        <SearchModal
                            activeItem={this.state.activeItem}
                            toggle={this.toggle}
                            onSave={this.handleSubmit}
                        /> : <InsertModal
                            flag = {this.state.flag}
                            activeItem={this.state.activeItem}
                            toggle={this.toggle}
                            onSave={this.handleSubmit}
                        />
                ) : null}
            </main>
        );
    }
}
export default DevPage;