import React, { Component } from "react";
import { Route, Link, NavLink, withRouter } from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import axios from "axios";
// import Register from "./Register";
// import ReactDOM from "react-dom";

class Login extends Component {
    constructor(props){
        super(props);
        this.state={
            username:'',
            password:'',
            isLoggedIn: false
        }
        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    handleLoginClick(event){
        this.setState({isLoggedIn: true});
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        var apiBaseUrl = "https://backend.cs411team1.web.illinois.edu/user/";
        var self = this;
        var payload={
            "Username":this.state.username,
            "password":this.state.password,
        }
        axios.get(proxyurl+apiBaseUrl+`${this.state.username}/${this.state.password}`)
            .then(function (response) {
                const str = response.data;
                const n = str.startsWith("Login Failed!");
                if(!n){
                    const res = str.split(': ')[1];
                    var userName =  self.state.username;
                    var data = {userid:res, username:userName};
                    data = JSON.stringify(data);
                    var path;
                    console.log(res);
                    if (res === "1" || res === "2" || res === "3" || res === "4" || res === "5"){
                        // path = {
                        //     pathname:'/DevPage',
                        //     query: data,
                        // }
                        path = `/DevPage/${data}`;
                        self.props.history.push(path);
                    } else if (res > 5){
                        // path = {
                        //     pathname:'/UserPage',
                        //     query: data,
                        // }
                        path = `/UserPage/${data}`;
                        self.props.history.push(path);
                    }else{
                        self.setState({isLoggedIn: false});
                    }
                }
                else{
                    //somthing when failed
                    self.setState({isLoggedIn: false});
                    self.props.history.push("/");
                    alert("Incorrect username or password");
                }
            })
            // .then(console.log(proxyurl+apiBaseUrl+this.state.username+'/'+this.state.password))
            .catch(function (error) {
                self.setState({isLoggedIn: false});
                console.log(error);
            });
    }

    handleLogoutClick() {
        this.setState({isLoggedIn: false});
    }

    render() {
        const isLoggedIn = this.state.isLoggedIn;
        let button;
        if (!isLoggedIn) {
            button = <RaisedButton label="Login" primary={true} style={style} onClick={(event) => this.handleLoginClick(event)}/>;
        } else {
            button = <RaisedButton label="Logout" primary={true} style={style} onClick={(event) => this.handleLogoutClick(event)}/>;
        }
        return (
            <div
                style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
                }}>
                <MuiThemeProvider>
                    <div>
                        <AppBar
                            title="Login"
                        />
                        <TextField
                            hintText="Enter your Username"
                            floatingLabelText="Username"
                            onChange = {(event,newValue) => this.setState({username:newValue})}
                        />
                        <br/>
                        <TextField
                            type="password"
                            hintText="Enter your Password"
                            floatingLabelText="Password"
                            onChange = {(event,newValue) => this.setState({password:newValue})}
                        />
                        <br/>
                        {button}
                        <Link to="/Register" style={{color:'black'} }>
                            <RaisedButton label="Register" primary={true} style={style}/>
                        </Link>
                    </div>
                </MuiThemeProvider>
            </div>
        );
    }
}
const style = {
    margin: 15,
};
export default withRouter(Login);