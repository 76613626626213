import React, { Component } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Input,
    Label
} from "reactstrap";


export default class InsertModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: this.props.activeItem
        };
    }
    handleChange = e => {
        let { name, value } = e.target;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        const activeItem = { ...this.state.activeItem, [name]: value };
        this.setState({ activeItem });
    };
    handleStockID = e => {
        //
        let { name, value } = e.target;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        if(isNaN(value)){
            value=value.replace(/[^\d]/g,'');
            console.log("Invalid Input");
        }
        const activeItem = { ...this.state.activeItem, [name]: value };
        this.setState({ activeItem });
    };

    render() {
        const { toggle, onSave } = this.props;
        const flag = this.props.flag;
        const flag_bool = flag === 3;
        return (
            <Modal isOpen={true} toggle={toggle}>
                <ModalHeader toggle={toggle}> Stock </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="StockID">StockID</Label>
                            <Input
                                type="text"
                                name="StockID"
                                value={this.state.activeItem.StockID}
                                onChange={this.handleStockID}
                                placeholder="Enter Stock ID"
                                readOnly={flag_bool}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="Date">Date</Label>
                            <Input
                                type="text"
                                name="Date"
                                value={this.state.activeItem.Date}
                                onChange={this.handleChange}
                                placeholder="Enter Date"
                                readOnly={flag_bool}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="LowPrice">LowPrice</Label>
                            <Input
                                type="text"
                                name="LowPrice"
                                value={this.state.activeItem.LowPrice}
                                onChange={this.handleChange}
                                placeholder="Enter LowPrice"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="HighPrice">HighPrice</Label>
                            <Input
                                type="text"
                                name="HighPrice"
                                value={this.state.activeItem.HighPrice}
                                onChange={this.handleChange}
                                placeholder="Enter HighPrice"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="OpenPrice">OpenPrice</Label>
                            <Input
                                type="text"
                                name="OpenPrice"
                                value={this.state.activeItem.OpenPrice}
                                onChange={this.handleChange}
                                placeholder="Enter OpenPrice"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="ClosePrice">ClosePrice</Label>
                            <Input
                                type="text"
                                name="ClosePrice"
                                value={this.state.activeItem.ClosePrice}
                                onChange={this.handleChange}
                                placeholder="Enter ClosePrice"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="Volume">Volume</Label>
                            <Input
                                type="text"
                                name="Volume"
                                value={this.state.activeItem.Volume}
                                onChange={this.handleChange}
                                placeholder="Enter Volume"
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => onSave(this.state.activeItem)}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}